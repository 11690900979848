import React, {useState, useCallback} from 'react';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

import symbols from 'shared/ui/symbols';
import type {ValueOf} from 'shared/types/helpers';
import EvergreenResponsiveProvider, {Device, useDevice} from 'shared/ui/providers/responsive';
import Dialog, {type ContainerProps} from 'shared/ui/organisms/dialog/base';
import {DIALOG_ROLES} from 'shared/ui/organisms/dialog/constants';
import Modal, {Content} from 'shared/ui/organisms/dialog/modal';
import {findChildrenOfType} from 'shared/ui/organisms/dialog/modal/component';
import NoIcon from 'shared/ui/atoms/icon/noIcon';

import DrawerHeader from './drawerHeader';

import getDropdownPositionScale from './helpers/getDropdownPositionScale';
import getDocumentHeight from './helpers/getDocumentHeight';
import getDialogPosition from './helpers/getDialogPosition';
import checkPositionFit from './helpers/checkPositionFit';
import checkIsVisible from './helpers/checkIsVisible';

import useDropdownLayoutMutation from './hooks/useDropdownLayoutMutation';
import useDrawerSwipeDown from './hooks/useDrawerSwipeDown';

import {POSITIONS, SIZES} from './constants';

import styles from './styles.scss';

export type Size = (typeof SIZES)[keyof typeof SIZES];

export type Position = ValueOf<typeof POSITIONS>;

export type AnchoredDropdownProps = Omit<ContainerProps, 'onClickOutside' | 'onEscapePress'> & {
  /** The function that will be executed when the backdrop is clicked or the ESC button is pressed. */
  onClose?: (event?: React.SyntheticEvent) => void;
  /** Set the width of dropdown. By default fits its content. */
  size?: Size;
  /** Set the preferred vertical position defaults to BOTTOM */
  defaultVerticalPosition?: typeof POSITIONS.TOP | typeof POSITIONS.BOTTOM | typeof POSITIONS.VERTICAL_CENTER;
  /** Set the preferred horizontal position defaults to LEFT */
  defaultHorizontalPosition?: typeof POSITIONS.LEFT | typeof POSITIONS.RIGHT | typeof POSITIONS.HORIZONTAL_CENTER;
  /** Set the dropdown width equal to the trigger width */
  fit?: boolean;
  /** If true, the dropdown will stick to the bottom or top of the page if it does not fit */
  stick?: boolean;
  /** Disable the auto-placement logic (useful for custom placement handling) */
  disableAutoPlacement?: boolean;
  /** Renders the dropdown as modal for tablet and bottom-drawer for mobile. */
  dropdownAsDrawer?: boolean;
  /** The label for the dropdown header when rendered as drawer. */
  drawerLabel?: React.ReactNode;
  /** In dropdownAsDrawer mode, this prop allows us to keep the dropdown at the initial target element and not render at body, defaults to false. */
  keepToElement?: boolean;
  /** When freeze is true, the dropdown will follow its trigger in the page. If false, it will freeze in its last position.*/
  freeze?: boolean;
  /** When seamless is true and is dropdownAsDrawer, the backdrop will not be displayed */
  seamless?: boolean;
  /** The reference of the HTML element that we want to target the dropdown */
  target?: HTMLElement | SVGElement | null;
  /** Hides the dropdown when the target is hidden */
  hideOnTargetHidden?: boolean;
};

export const AnchoredDropdown: React.FC<AnchoredDropdownProps> = ({
  defaultVerticalPosition = POSITIONS.BOTTOM,
  defaultHorizontalPosition = POSITIONS.LEFT,
  children,
  target,
  backdrop,
  seamless,
  size,
  fit,
  stick,
  freeze,
  hideOnTargetHidden,
  disableAutoPlacement,
  keepToElement,
  dropdownAsDrawer,
  drawerLabel,
  onClose,
  onTransitionEnterStarted,
  onTransitionExitFinished,
  onScroll,
  open,
  ...props
}) => {
  const [targetElement, setTargetElement] = useState<HTMLElement | SVGElement | null>();
  const [dialogElement, setDialogElement] = useState<HTMLElement | null>();

  const [dialogStyle, setDialogStyle] = useState({});
  const [isFullheightDrawer, setFullheightDrawer] = useState(false);

  const [device] = useDevice();
  const isMobile = device === Device.Mobile;
  const isTablet = device === Device.Tablet;

  const isDropdownAsDrawer = (isMobile || isTablet) && dropdownAsDrawer;
  const isAutoPlacementDisabled = disableAutoPlacement || isDropdownAsDrawer;
  const isBackdropEnabled = backdrop || (isDropdownAsDrawer && !seamless);
  const isHideOnTargetHiddenEnabled = hideOnTargetHidden && !isDropdownAsDrawer;

  const handleDialogOpenStarted = useCallback(
    (dialogEl: Element) => {
      if (typeof onTransitionEnterStarted === 'function') {
        onTransitionEnterStarted(dialogEl);
      }

      setDialogElement(dialogEl as HTMLElement);
      setTargetElement(target || dialogEl?.parentElement);

      const halfHeightOfScreen = 0.5 * getDocumentHeight();

      if (isMobile && dialogEl.scrollHeight > halfHeightOfScreen) {
        setFullheightDrawer(true);
      } else {
        setFullheightDrawer(false);
      }
    },
    [target, onTransitionEnterStarted, isMobile]
  );

  const handleDialogCloseFinished = useCallback(
    (dialogEl: Element) => {
      if (typeof onTransitionExitFinished === 'function') {
        onTransitionExitFinished(dialogEl);
      }

      setDialogElement(null);
      setTargetElement(null);
      setFullheightDrawer(false);
    },
    [onTransitionExitFinished]
  );

  const updateDialogPosition = useCallback(() => {
    if (isAutoPlacementDisabled) {
      return setDialogStyle({});
    }

    if (!dialogElement || !targetElement) {
      return;
    }

    if (!checkIsVisible(targetElement) && open) {
      onClose?.();
      return;
    }

    const [positionVertical, positionHorizontal] = getDialogPosition({
      target: targetElement,
      element: dialogElement,
      defaultVerticalPosition,
      defaultHorizontalPosition,
      stick: !!stick
    });

    targetElement.setAttribute('data-dropdown-vertical-position', positionVertical);
    targetElement.setAttribute('data-dropdown-horizontal-position', positionHorizontal);
    dialogElement.setAttribute('data-position-vertical', positionVertical);
    dialogElement.setAttribute('data-position-horizontal', positionHorizontal);

    if (stick && checkPositionFit({target: targetElement, element: dialogElement, positionVertical})) {
      dialogElement.setAttribute('data-dropdown-stick', '');
    } else {
      dialogElement.removeAttribute('data-dropdown-stick');
    }

    setDialogStyle(
      getDropdownPositionScale({
        target: targetElement,
        element: dialogElement,
        positionVertical,
        positionHorizontal,
        fit: !!fit,
        stick: !!stick
      })
    );
  }, [
    isAutoPlacementDisabled,
    targetElement,
    dialogElement,
    defaultVerticalPosition,
    defaultHorizontalPosition,
    open,
    fit,
    stick,
    onClose
  ]);

  const setScrollPosition = useCallback(
    debounce(element => {
      if (element instanceof HTMLElement === false || !element.closest) {
        return;
      }
      const {offsetHeight = 0, scrollHeight = 0, scrollTop = 0} = element;

      const dialogContainerEl = element.closest(`[data-role="${DIALOG_ROLES.Container}"]`);
      if (dialogContainerEl) {
        dialogContainerEl.setAttribute(
          'data-scroll-position',
          scrollTop === 0 ? 'start' : scrollHeight - (offsetHeight + scrollTop) === 0 ? 'end' : 'middle'
        );
      }
    }),
    []
  );

  const handleScroll = useCallback(
    (event: React.UIEvent | Event) => {
      setScrollPosition(event.target);

      if (typeof onScroll === 'function') {
        onScroll(event);
      }
    },
    [onScroll, setScrollPosition]
  );

  const getDropdownContent = () => {
    let actionsComponent = null;
    let contentComponents = null;
    const drawerHeader = <DrawerHeader label={drawerLabel} hasArrow={isFullheightDrawer} onClose={onClose} />;

    const [firstActionChild] = findChildrenOfType(children, symbols.Dialog.Actions) as React.ReactElement[];
    const foundContentChildren = findChildrenOfType(children, symbols.Dialog.Content) as React.ReactElement[];

    // When there are no actions and no content, we render the children directly (legacy behavior)
    if (!firstActionChild && foundContentChildren.length === 0) {
      return {
        content: isDropdownAsDrawer ? (
          <Content>
            {drawerHeader}
            {children}
          </Content>
        ) : (
          children
        )
      };
    }

    // In dropdownAsDrawer mode, we render the drawerHeader and the children (actions and content are handled by Modal)
    if (isDropdownAsDrawer) {
      return {content: [<Content key="drawer-content">{drawerHeader}</Content>, ...React.Children.toArray(children)]};
    }

    if (firstActionChild) {
      actionsComponent = <firstActionChild.type {...firstActionChild.props} data-form-actions onClose={onClose} />;
    }

    if (foundContentChildren) {
      contentComponents = foundContentChildren.map((foundChild, key) => (
        <foundChild.type {...foundChild.props} key={key} />
      ));
    }

    return {content: contentComponents, actions: actionsComponent};
  };

  useDropdownLayoutMutation({
    dialogElement,
    targetElement,
    freeze,
    onMutation: updateDialogPosition,
    onTriggerOutOfView: isHideOnTargetHiddenEnabled ? onClose : undefined
  });

  useDrawerSwipeDown({
    enabled: isMobile && dropdownAsDrawer,
    dialogElement,
    onSwipeDown: onClose
  });

  const classNames = clsx(
    {
      [styles.dialog]: true,
      [styles['mid-size']]: size === SIZES.mid,
      [styles['large-size']]: size === SIZES.large,
      [styles['disable-auto-placement']]: isAutoPlacementDisabled,
      [styles['dropdown-as-drawer']]: isDropdownAsDrawer,
      [styles['full-height-drawer']]: isDropdownAsDrawer && isFullheightDrawer,
      [styles['hide-on-target-hidden']]: isHideOnTargetHiddenEnabled
    },
    props.className
  );

  const {content = null, actions = null} = getDropdownContent();

  if (isDropdownAsDrawer && !keepToElement) {
    return (
      <Modal
        role={Dialog.Container.constants.ROLE.DIALOG}
        backdrop={isBackdropEnabled}
        {...props}
        open={open}
        className={classNames}
        mobile={false}
        DismissIcon={<NoIcon />}
        data-dropdown-as-drawer
        onClickOutside={onClose}
        onEscapePress={onClose}
        onClose={onClose}
        onScroll={onScroll}
        onTransitionEnterStarted={handleDialogOpenStarted}
        onTransitionExitFinished={handleDialogCloseFinished}
      >
        {content}
        {actions}
      </Modal>
    );
  }

  return (
    <Dialog.Container
      role={Dialog.Container.constants.ROLE.DIALOG}
      backdrop={isBackdropEnabled}
      focusBack={isHideOnTargetHiddenEnabled === true ? false : undefined}
      {...props}
      open={open}
      style={{...props.style, ...dialogStyle}}
      className={classNames}
      data-dropdown-as-drawer={isDropdownAsDrawer ? true : undefined}
      onClickOutside={onClose}
      onEscapePress={onClose}
      onTransitionEnterStarted={handleDialogOpenStarted}
      onTransitionExitFinished={handleDialogCloseFinished}
      onScroll={handleScroll}
    >
      {content}
      {actions}
    </Dialog.Container>
  );
};

const ResponsiveAnchoredDropdown = (props: AnchoredDropdownProps) => (
  <EvergreenResponsiveProvider>
    <AnchoredDropdown {...props} />
  </EvergreenResponsiveProvider>
);

ResponsiveAnchoredDropdown.constants = {...Dialog.Container.constants};

ResponsiveAnchoredDropdown[symbols.Dialog.DropDown] = true;

ResponsiveAnchoredDropdown.displayName = 'Dropdown';

export default ResponsiveAnchoredDropdown;
