/**
 * Returns the first containing block element of given element.
 */
const getContainingBlockElement = (element: HTMLElement | null) => {
  if (!element) {
    return null;
  }

  let currentElement = element.parentElement;

  while (currentElement) {
    const computedStyle = getComputedStyle(currentElement);

    if (['inline-size', 'size'].includes(computedStyle.containerType)) {
      return currentElement;
    }

    if (computedStyle.transform && computedStyle.transform !== 'none') {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
  }

  return null;
};

export default getContainingBlockElement;
