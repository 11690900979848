import {ValueOf} from 'shared/types/helpers';
import {POSITIONS} from 'shared/ui/organisms/dropdown';
import {PLACEMENTS} from '../constants';

type Placement = (typeof PLACEMENTS)[keyof typeof PLACEMENTS];

type Position = ValueOf<typeof POSITIONS>;

type YPosition = Extract<Position, 'TOP' | 'BOTTOM' | 'VERTICAL_CENTER'>;
type XPosition = Extract<Position, 'LEFT' | 'RIGHT' | 'HORIZONTAL_CENTER'>;

const getCoordinatesFromPlacementString = (placement: Placement): [YPosition, XPosition] => {
  switch (placement) {
    case PLACEMENTS.top:
      return [POSITIONS.TOP, POSITIONS.HORIZONTAL_CENTER];
    case PLACEMENTS.bottom:
      return [POSITIONS.BOTTOM, POSITIONS.HORIZONTAL_CENTER];
    case PLACEMENTS.left:
      return [POSITIONS.VERTICAL_CENTER, POSITIONS.LEFT];
    case PLACEMENTS.right:
      return [POSITIONS.VERTICAL_CENTER, POSITIONS.RIGHT];
    case PLACEMENTS.topRight:
      return [POSITIONS.TOP, POSITIONS.RIGHT];
    case PLACEMENTS.topLeft:
      return [POSITIONS.TOP, POSITIONS.LEFT];
    case PLACEMENTS.bottomLeft:
      return [POSITIONS.BOTTOM, POSITIONS.LEFT];
    case PLACEMENTS.bottomRight:
      return [POSITIONS.BOTTOM, POSITIONS.RIGHT];
    default:
      return [POSITIONS.TOP, POSITIONS.HORIZONTAL_CENTER];
  }
};

export default getCoordinatesFromPlacementString;
