import React from 'react';
import symbols from 'shared/ui/symbols';
import {PropsOf} from 'shared/types/helpers';
import SecondaryHeading from 'shared/ui/atoms/heading/secondary';
import TertiaryHeading from 'shared/ui/atoms/heading/tertiary';
import {DIALOG_ROLES} from '../constants';

export type TitleContainerProps =
  | ({
      isMobile: true;
      children: React.ReactNode;
    } & PropsOf<typeof TertiaryHeading>)
  | ({
      isMobile: false | undefined;
      children: React.ReactNode;
    } & PropsOf<typeof SecondaryHeading>);

export const TitleContainer = ({children, isMobile, ...props}: TitleContainerProps) => {
  return isMobile ? (
    <TertiaryHeading as="h2" strong primary {...props}>
      {children}
    </TertiaryHeading>
  ) : (
    <SecondaryHeading strong {...props}>
      {children}
    </SecondaryHeading>
  );
};

export type NakedDialogTitleProps = React.HTMLAttributes<HTMLDivElement> & {
  mobile?: boolean;
};

const NakedDialogTitle = ({children, mobile, ...props}: NakedDialogTitleProps) => (
  <div {...props} data-role={DIALOG_ROLES.Title}>
    {children}
  </div>
);

export type DialogTitleProps = React.HTMLAttributes<HTMLDivElement> & {
  mobile?: boolean;
};

const DialogTitle = ({children, mobile, ...props}: DialogTitleProps) => (
  <NakedDialogTitle {...props}>
    <TitleContainer isMobile={mobile}>{children}</TitleContainer>
  </NakedDialogTitle>
);

DialogTitle.displayName = 'Dialog.Title';

DialogTitle[symbols.Dialog.Title] = true;

NakedDialogTitle[symbols.Dialog.Title] = true;

export {NakedDialogTitle};

export default DialogTitle;
