import Container, {type ContainerProps} from './container';
import Actions, {type DialogActionsProps as ActionProps} from './actions';
import Title, {type DialogTitleProps as TitleProps} from './title';
import Content, {type DialogContentProps as ContentProps} from './content';

export type {ContainerProps, ActionProps, TitleProps, ContentProps};

export default {
  Container,
  Actions,
  Title,
  Content
};
