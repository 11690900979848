import React, {ReactElement, ReactNode} from 'react';
import symbols from 'shared/ui/symbols';
import {DIALOG_ROLES} from '../constants';
import {CLOSE_ACTION} from './constants';
import './styles.scss';

export type DialogActionsProps = {
  /** Accepts Button elements only */
  children: ReactNode;
  /** Zero based index of the dismiss button in passed children */
  dismissIndex?: number;
  /** Used internally to handle close dialog on unsupported browsers. It gets wired on Dialog.Container */
  onUnsupportedClose?: (...args: unknown[]) => void;
} & React.HTMLAttributes<HTMLDivElement>;

const ensureChildIsButton = (child: ReactElement): boolean => {
  if (child && symbols.Button.includes(child.type)) {
    return true;
  }

  throw new Error('Dialog.Actions accepts only UI/Button as children');
};

const markClonedActionChild = (
  child: ReactElement,
  {value, ...props}: {value?: string; [key: string]: any}
): ReactElement => {
  return React.cloneElement(child, {
    ...props,
    ...(value ? {value} : {})
  });
};

const processChildrenAsButtons = ({
  children,
  dismissIndex,
  onUnsupportedClose
}: {
  children: ReactNode;
  dismissIndex: number;
  onUnsupportedClose?: (...args: any[]) => void;
}): ReactElement[] => {
  if (React.Children.count(children) === 0) {
    throw new Error('Dialog.Actions accepts at least one UI/Button as child');
  }

  const processed: ReactElement[] = [];

  React.Children.forEach(children, (child, index) => {
    if (!React.isValidElement(child)) {
      return;
    }

    const props: {[key: string]: any} = {
      key: index
    };

    ensureChildIsButton(child);

    if (dismissIndex === index) {
      const onChildClick = (child as ReactElement<{onClick?: (...args: any[]) => void}>)?.props?.onClick;
      props.onClick = (...args: any[]) => {
        onUnsupportedClose?.(...(args as unknown[]));
        onChildClick?.(...(args as unknown[]));
      };
      props.value = CLOSE_ACTION.DISMISS;
    }

    processed.push(markClonedActionChild(child, props));
  });

  return processed;
};

const DialogActions = ({children, dismissIndex = -1, onUnsupportedClose, ...props}: DialogActionsProps) => {
  return (
    <div data-role={DIALOG_ROLES.Actions} {...props}>
      {processChildrenAsButtons({children, dismissIndex, onUnsupportedClose})}
    </div>
  );
};

DialogActions.displayName = 'Dialog.Actions';

DialogActions[symbols.Dialog.Actions] = true;

DialogActions.constants = {
  ACTION: CLOSE_ACTION
};

export default DialogActions;
