import React from 'react';
import clsx from 'clsx';
import propsFilter from 'shared/ui/helpers/propsFilter';

import styles from './styles.scss';

export type SkeletonProps = React.HTMLAttributes<HTMLDivElement> & {
  height?: string;
  width?: string;
  borderRadius?: string;
  darker?: boolean;
  reveal?: boolean;
  className?: string;
  style?: React.CSSProperties;
  'data-ui'?: string;
  'data-testId'?: string;
};

const Skeleton = ({
  width,
  height,
  children,
  darker,
  reveal = false,
  borderRadius = '4px',
  style = {},
  ...props
}: SkeletonProps) => {
  const filteredProps = propsFilter(props).ariaAttributes().dataAttributes().styles().getFiltered() as typeof props;
  const isWrap = !width && !height;
  return (
    <div
      {...filteredProps}
      className={clsx(
        {
          [styles.skeleton]: true,
          [styles.darker]: darker,
          [styles.wrap]: isWrap,
          [styles.reveal]: reveal
        },
        filteredProps.className
      )}
      style={{...style, width, height, borderRadius}}
    >
      {children}
    </div>
  );
};

Skeleton.displayName = 'Skeleton';

export default Skeleton;
