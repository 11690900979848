import React, {useCallback, useState, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import ReactDOM from 'react-dom';

import propsFilter from 'shared/ui/helpers/propsFilter';
import getRandomString from 'shared/ui/helpers/getRandomString';

import Ref from 'shared/ui/behaviors/ref';

import {TRANSITION_DURATION} from 'shared/ui/organisms/dialog/base/constants';
import getCoordinatesFromPlacementString from './helpers/getCoordinatesFromPlacementString';
import TooltipDropdown, {type TooltipDropdownProps} from './dropdown';
import {PLACEMENTS} from './constants';

export const INSTANT_OPEN_THRESHOLD = 600;

const STATUSES = {
  open: 'open',
  closing: 'closing'
} as const;

export type Placement = (typeof PLACEMENTS)[keyof typeof PLACEMENTS];

export type TooltipProps = {
  /* Controls whether the tooltip is displayed. */
  show?: boolean;
  /* Controls where the tooltip will appear in respect to the element it is anchored to. */
  placement?: Placement;
  /* The time, in milliseconds that the tooltip should wait before it is displayed. Default: 0ms */
  delay?: number;
  /** The callback function to be called when target element is clicked. */
  onClick?: (event: React.MouseEvent<HTMLInputElement> | Event) => void;
  /** The callback function to be called when tooltip is closed. */
  onClose?: () => void;
} & Omit<TooltipDropdownProps, 'defaultVerticalPosition' | 'defaultHorizontalPosition'>;

const clearTimeouts = (timeoutIds: ReturnType<typeof setTimeout>[]) => timeoutIds.forEach(id => clearTimeout(id));

const Tooltip: React.FC<TooltipProps> = ({
  show = true,
  delay = 300,
  placement = 'top',
  beak = false,
  title,
  content,
  inverted,
  fixed,
  id: _id,
  children,
  onClick,
  textAlign,
  textNoWrap,
  ...restProps
}) => {
  const randomIdRef = useRef(getRandomString());
  const openTimeoutRef = useRef<ReturnType<typeof setTimeout>[]>([]);
  const transitionTimeoutRef = useRef<ReturnType<typeof setTimeout>[]>([]);

  const id = _id || randomIdRef.current;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [targetElement, setTargetElement] = useState<HTMLElement | SVGElement | null>(null);

  // is actually a touch device
  const isMobileOrTablet = 'ontouchstart' in window || 'msMaxTouchPoints' in navigator;

  const {...extraProps} = propsFilter(restProps).dataAttributes().styles().getFiltered();

  const transitionProps = propsFilter(restProps)
    .like(/^onTransition/)
    .getFiltered();

  const position = useMemo(() => {
    const [defaultVerticalPosition, defaultHorizontalPosition] = getCoordinatesFromPlacementString(placement);

    return {
      defaultVerticalPosition,
      defaultHorizontalPosition
    };
  }, [placement]);

  const activateTooltip = useCallback(
    (e: React.MouseEvent<HTMLElement> | TouchEvent | Event) => {
      clearTimeouts(openTimeoutRef.current);
      if ((e.target as HTMLElement).getAttribute('focus-back')) {
        return;
      }

      if (document.body.getAttribute('data-has-tooltip')) {
        document.body.setAttribute('data-has-tooltip', STATUSES.open);
        setIsTooltipVisible(true);
        return;
      }

      const openTimeoutId: ReturnType<typeof setTimeout> = setTimeout(() => {
        setIsTooltipVisible(true);
      }, delay);
      openTimeoutRef.current.push(openTimeoutId);

      const transitionTimeoutId = setTimeout(() => {
        document.body.setAttribute('data-has-tooltip', STATUSES.open);
      }, delay + TRANSITION_DURATION);
      transitionTimeoutRef.current.push(transitionTimeoutId);
    },
    [delay]
  );

  const deactivateTooltip = useCallback(() => {
    clearTimeouts(openTimeoutRef.current);
    setIsTooltipVisible(false);
    clearTimeouts(transitionTimeoutRef.current);

    if (document.body.getAttribute('data-has-tooltip')) {
      document.body.setAttribute('data-has-tooltip', STATUSES.closing);
      setTimeout(() => {
        if (document.body.getAttribute('data-has-tooltip') === STATUSES.closing) {
          document.body.removeAttribute('data-has-tooltip');
        }
      }, INSTANT_OPEN_THRESHOLD);
    }
  }, []);

  const deactivateTooltipOnTouch = useCallback(
    (e: TouchEvent | Event) => {
      // stop tooltip from re-opening when clicking the targetElement immediately after closing
      if (targetElement && targetElement.contains(e.target as HTMLElement)) {
        e.stopPropagation();
      }
      deactivateTooltip();
      window.removeEventListener('touchstart', deactivateTooltipOnTouch, true);
    },
    [deactivateTooltip, targetElement]
  );

  useEffect(() => {
    return () => {
      clearTimeouts(openTimeoutRef.current);
      clearTimeouts(transitionTimeoutRef.current);
    };
  }, []);

  useLayoutEffect(() => {
    const deactivateTooltipOnScroll = () => {
      deactivateTooltip();
      if (isMobileOrTablet) {
        window.removeEventListener('touchstart', deactivateTooltipOnTouch, true);
      }
    };

    window.addEventListener('scroll', deactivateTooltipOnScroll, {capture: true});

    return () => window.removeEventListener('scroll', deactivateTooltipOnScroll, {capture: true});
  }, [deactivateTooltip, deactivateTooltipOnTouch, isMobileOrTablet]);

  const setTargetElementRef = useCallback(
    (target: HTMLElement | SVGElement | null) => {
      const isValidTarget = !!target && (target instanceof HTMLElement || target instanceof SVGElement);
      const isSameTarget = targetElement === target;

      if (!isValidTarget || isSameTarget) {
        return;
      }

      setTargetElement(target);
    },
    [targetElement]
  );

  useLayoutEffect(() => {
    if (!targetElement) {
      return;
    }

    const checkForRemovedTarget = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        for (const element of mutation.removedNodes) {
          if (element === targetElement) {
            setTargetElement(null);
          }
        }
      }
    };

    /**
     * This is needed because some icons are loaded async and would lose their event listeners otherwise.
     */
    const mutationObserver = new MutationObserver(checkForRemovedTarget);
    if (targetElement.parentNode) {
      mutationObserver.observe(targetElement.parentNode, {childList: true, subtree: true});
    }

    const closeTooltipAndDeactivateFocus = () => {
      const hasEnteredPage = document.visibilityState === 'visible';

      if (hasEnteredPage) {
        window.requestAnimationFrame(deactivateTooltip);
      }
    };

    const activateTooltipOnTouch = (e: TouchEvent | Event) => {
      activateTooltip(e);
      window.addEventListener('touchstart', deactivateTooltipOnTouch, true);
    };

    targetElement.setAttribute('aria-describedby', id);

    targetElement.addEventListener('focus', activateTooltip, false);
    targetElement.addEventListener('blur', deactivateTooltip, false);
    window.addEventListener('visibilitychange', closeTooltipAndDeactivateFocus);

    if (onClick) {
      targetElement.addEventListener('click', onClick, false);
    }

    if (isMobileOrTablet) {
      targetElement.addEventListener('touchstart', activateTooltipOnTouch, false);
    } else {
      targetElement.addEventListener('mouseenter', activateTooltip, false);
      targetElement.addEventListener('mouseleave', deactivateTooltip, false);
    }

    return () => {
      mutationObserver.disconnect();

      targetElement.removeEventListener('focus', activateTooltip, false);
      targetElement.removeEventListener('blur', deactivateTooltip, false);
      window.removeEventListener('visibilitychange', closeTooltipAndDeactivateFocus);

      if (onClick) {
        targetElement.removeEventListener('click', onClick, false);
      }

      if (isMobileOrTablet) {
        targetElement.removeEventListener('touchstart', activateTooltipOnTouch, false);
        window.removeEventListener('touchstart', deactivateTooltipOnTouch, true);
      } else {
        targetElement.removeEventListener('mouseenter', activateTooltip, false);
        targetElement.removeEventListener('mouseleave', deactivateTooltip, false);
      }
    };
  }, [id, targetElement, isMobileOrTablet, onClick, activateTooltip, deactivateTooltip, deactivateTooltipOnTouch]);

  const isOpen = !!targetElement && isTooltipVisible;

  if (show && (title || content)) {
    return (
      <>
        <Ref $ref={setTargetElementRef}>{children}</Ref>
        {ReactDOM.createPortal(
          <TooltipDropdown
            id={id}
            open={isOpen}
            target={targetElement}
            disableAutoRevertFocus
            title={title}
            content={content}
            inverted={inverted}
            fixed={fixed}
            beak={beak}
            textAlign={textAlign}
            textNoWrap={textNoWrap}
            role="tooltip"
            aria-hidden={!isTooltipVisible}
            focusable
            {...extraProps}
            {...position}
            {...transitionProps}
          />,
          document.body
        )}
      </>
    );
  }

  return <Ref>{children}</Ref>;
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
