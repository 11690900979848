/**
 * Returns the bounding box of given element.
 * If a container is given, it returns the bounding box relative to the container.
 */
const getBoundingBox = (element: HTMLElement | SVGElement, options: {container?: HTMLElement | null} = {}) => {
  const {container} = options;

  if (!container) {
    return element.getBoundingClientRect();
  }

  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return {
    width: elementRect.width,
    height: elementRect.height,
    y: elementRect.y - containerRect.y,
    x: elementRect.x - containerRect.x,
    top: elementRect.top - containerRect.top,
    left: elementRect.left - containerRect.left,
    right: elementRect.right - containerRect.left,
    bottom: elementRect.bottom - containerRect.top
  };
};

export default getBoundingBox;
