import React from 'react';
import clsx from 'clsx';

import TextSecondary, {type TextSecondaryProps} from 'shared/ui/atoms/text/secondary';
import {AnchoredDropdown as Dropdown, type AnchoredDropdownProps} from 'shared/ui/organisms/dropdown';
import symbols, {withSymbol} from 'shared/ui/symbols';

export const INSTANT_OPEN_THRESHOLD = 600;

import styles from './styles.scss';

const TEXT_ALIGNMENTS = {
  left: 'left',
  right: 'right',
  center: 'center'
} as const;

const TEXT_KINDS = {
  title: 'title',
  content: 'content'
} as const;

const COLORS = {
  [TEXT_KINDS.title]: {
    default: 'white',
    inverted: 'grey700'
  },
  [TEXT_KINDS.content]: {
    default: 'grey300',
    inverted: 'grey600'
  }
} as const;

type TextProps = TextSecondaryProps & {
  text?: React.ReactNode;
  textKind: keyof typeof TEXT_KINDS;
  inverted?: boolean;
};
const Text = ({text, textKind, inverted, ...restProps}: TextProps) => {
  if (!text) {
    return null;
  }

  const props = {
    neutral: true,
    color: inverted ? COLORS[textKind].inverted : COLORS[textKind].default,
    ...restProps
  };

  return <TextSecondary {...props}>{text}</TextSecondary>;
};

export type TooltipDropdownProps = React.PropsWithChildren<AnchoredDropdownProps> & {
  /** the title of the tooltip. */
  title?: string | JSX.Element;
  /** The optional content of the tooltip. Can be either a string or react element. Tooltips with content are larger */
  content?: string | JSX.Element;
  /** ID of the tooltip. */
  id?: string;
  /** Controls whether the background-color and font-color should be inverted. */
  inverted?: boolean;
  /** Controls whether the arrow should be shown. Defaults to false */
  beak?: boolean;
  /** Controls whether the tooltip will be fixed width or adaptive. */
  fixed?: boolean;
  /** Controls the text alignment of the tooltip. */
  textAlign?: keyof typeof TEXT_ALIGNMENTS;
  /** If `true` the text will no wrap to new lines. Default: `false` */
  textNoWrap?: boolean;
};

const TooltipDropdown = ({
  open,
  target,
  inverted,
  content,
  title,
  beak,
  fixed,
  textNoWrap,
  textAlign,
  className,
  ...props
}: TooltipDropdownProps) => {
  return (
    <Dropdown
      open={open}
      freeze={!open}
      target={target}
      {...props}
      className={clsx(
        styles.tooltip,
        {
          [styles.inverted]: inverted,
          [styles.fixed]: typeof fixed !== 'undefined' ? fixed : inverted,
          [styles.large]: !!content,
          [styles.beak]: !!beak,
          [styles['text-nowrap']]: textNoWrap,
          [styles[`text-${textAlign}`]]: !!textAlign && !!TEXT_ALIGNMENTS[textAlign]
        },
        className
      )}
    >
      <span>
        {content ? (
          <span className={styles['content-wrapper']}>
            {title ? <Text text={title} textKind={TEXT_KINDS.title} inverted={inverted} strong={inverted} /> : null}
            <Text text={content} textKind={TEXT_KINDS.content} inverted={inverted} />
          </span>
        ) : (
          <Text text={title} textKind={TEXT_KINDS.title} inverted={inverted} />
        )}
      </span>
    </Dropdown>
  );
};

withSymbol(symbols.Dialog.DropDown, TooltipDropdown);

export default TooltipDropdown;
