const checkIsVisible = (targetElement?: HTMLElement | SVGElement | null) => {
  if (typeof targetElement?.checkVisibility === 'function') {
    return targetElement.checkVisibility();
  }

  const checkIfHiddenParent = (element?: HTMLElement | SVGElement | null) => {
    while (element) {
      const style = window.getComputedStyle(element);
      if (style.display === 'none') {
        return false;
      }
      element = element.parentElement;
    }
    return true;
  };

  return checkIfHiddenParent(targetElement);
};

export default checkIsVisible;
